<template>
  <v-container class="general list">
    <page-title :component="'AppPageList'" tooltip="AppPageListTitleTooltip">
      <template slot="actions">
        <LanguageSwitcher />
      </template>
    </page-title>
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && (!menu.destinations || !menu.destinations.length)"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("app_page.empty_list") }}
        </v-flex>
      </v-layout>
    </transition>
    <v-layout
      v-if="menu.destinations && menu.destinations.length"
      class="row wrap"
    >
      <v-flex class="xs12">
        <vue-draggable
          class="vue-draggable"
          v-model="menu.destinations"
          :handle="'.draggable-handle'"
          @change="reorderGroupMenuDestinations"
        >
          <v-layout
            v-for="(destination, i) in menu.destinations"
            :key="`destination-${i}`"
            :style="{ width: '100%' }"
            class="row wrap"
          >
            <v-flex v-if="i !== 0" class="xs12 my-3">
              <v-divider></v-divider>
            </v-flex>
            <v-layout class="row align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-if="menu.destinations && menu.destinations.length"
                    class="draggable-handle grey--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <font-awesome-icon icon="grip-vertical" />
                  </span>
                </template>
                <span v-html="$t('app_page.draggable_handle_tooltip')"></span>
              </v-tooltip>
              <v-flex class="xs12 mx-4">
                <v-layout class="row align-center">
                  <IconSelector
                    v-model="destination.icon"
                    @change="
                      updateDestination(destination, {
                        icon: destination.icon,
                      })
                    "
                  />
                  <v-flex class="xs12 ml-4">
                    <div>
                      <a
                        v-show="!destination.is_name_editable"
                        class="sw-h5 font-weight-light sw-primary"
                        @click.stop="toggleNameEdit(destination, i)"
                        >{{ destination.name }}</a
                      >
                      <v-text-field
                        v-show="destination.is_name_editable"
                        ref="name"
                        v-model="destination.name"
                        :label="$tc('name', 1)"
                        maxlength="40"
                        hide-details
                        class="mb-2"
                        @blur="saveNameEdit(destination, i)"
                        @keyup.enter="saveNameEdit(destination, i)"
                      ></v-text-field>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn
                      @click="
                        updateDestination(destination, {
                          promoted: destination.promoted ? 0 : 1,
                        })
                      "
                      :loading="
                        pendingApiCalls[`destination-${destination.id}`]
                      "
                      icon
                      class="ma-0"
                      :class="destination.promoted ? 'sw-accent' : 'grey--text'"
                    >
                      <v-icon small>
                        {{ destination.promoted ? "star" : "star_outline" }}
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-html="$t('app_page.promoted_menu_item')"></span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-menu transition="slide-y-transition" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          class="ma-0"
                          :disabled="canChangeVisibility(destination.component)"
                          :loading="
                            pendingApiCalls[`destination-${destination.id}`]
                          "
                        >
                          <font-awesome-icon
                            :icon="['fad', 'user-friends']"
                            class="sw-accent"
                          />
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-tile
                          v-for="(group, index) in accessGroups"
                          :key="index"
                          @click="
                            updateDestination(destination, {
                              is_public: group.is_public,
                            })
                          "
                        >
                          <v-list-tile-title>
                            <v-icon
                              small
                              class="mr-2"
                              :class="
                                group.is_public === destination.is_public
                                  ? 'sw-accent'
                                  : 'grey--text lighten-1'
                              "
                              :style="{ marginBottom: '4px' }"
                              >fiber_manual_record</v-icon
                            >
                            {{ group.name }}
                          </v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </span>
                </template>
                <span v-html="$t('app_page.is_public_tooltip')"></span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-btn
                      @click="
                        updateDestination(destination, {
                          enabled: destination.enabled ? 0 : 1,
                        })
                      "
                      :loading="
                        pendingApiCalls[`destination-${destination.id}`]
                      "
                      icon
                      class="ma-0"
                      :class="destination.enabled ? 'sw-accent' : 'grey--text'"
                    >
                      <v-icon small>
                        {{
                          destination.enabled ? "visibility" : "visibility_off"
                        }}
                      </v-icon>
                    </v-btn>
                  </span>
                </template>
                <span v-html="$t('app_page.enabled_tooltip')"></span>
              </v-tooltip>
              <v-btn
                icon
                class="ma-0"
                @click.stop="editDestination(destination)"
                :loading="pendingApiCalls[`destination-${destination.id}`]"
                :disabled="destination.component !== 'ArticlePage'"
              >
                <font-awesome-icon
                  :class="{
                    'sw-accent': destination.component === 'ArticlePage',
                  }"
                  icon="pen"
                />
              </v-btn>
              <v-btn
                icon
                class="ma-0"
                @click.stop="deleteGroupMenuDestination(destination.id)"
                :loading="pendingApiCalls[`destination-${destination.id}`]"
                :disabled="destination.component !== 'ArticlePage'"
              >
                <font-awesome-icon
                  :class="{
                    'sw-accent': destination.component === 'ArticlePage',
                  }"
                  icon="trash"
                />
              </v-btn>
            </v-layout>
          </v-layout>
        </vue-draggable>
      </v-flex>
    </v-layout>
    <AppSpeedDial
      @click="
        $router.push({
          name: 'app_page_wizard_create',
          params: { menu_id: menu.id },
        })
      "
      :hint="$t('app_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import draggable from "vuedraggable";

export default {
  data: () => ({
    isLoading: false,
    pendingApiCalls: {},
    menu: {
      destinations: [],
    },
    tempCache: {},
  }),
  computed: {
    ...mapGetters(["appContentLanguage"]),
    groupId() {
      return this.$route.params.group_id;
    },
    groupMenuId() {
      return this.$route.params.menu_id;
    },
    accessGroups() {
      return [
        { name: this.$t("app_page.visibility_all"), is_public: null },
        { name: this.$t("app_page.visibility_authorized"), is_public: false },
        { name: this.$t("app_page.visibility_unauthorized"), is_public: true },
      ];
    },
  },
  components: {
    LanguageSwitcher,
    "vue-draggable": draggable,
  },
  mounted() {
    this.getAppMenu();
  },
  watch: {
    appContentLanguage() {
      this.getAppMenu();
    },
  },
  methods: {
    canChangeVisibility(component) {
      if (component === "QuestionPage") {
        return true;
      }
    },
    toggleNameEdit(destination, index) {
      this.tempCache = Object.assign({}, { name: destination.name });

      this.$set(destination, "is_name_editable", !destination.is_name_editable);

      if (destination.is_name_editable) {
        this.$nextTick(() => this.$refs.name[index].focus());
      }
    },
    saveNameEdit(destination, index) {
      if (!destination.name) {
        destination.name = this.tempCache.name;
      }

      const specs = { name: destination.name };

      if (destination.name !== this.tempCache.name) {
        this.updateDestination(destination, specs);
      }

      this.toggleNameEdit(destination, index);
    },
    updateDestination(destination, payload) {
      const specs = [
        this.groupId,
        this.menu.id,
        destination.id,
        { ...payload, lang: this.appContentLanguage },
      ];

      this.$set(this.pendingApiCalls, `destination-${destination.id}`, true);

      this.$api.groupMenuDestinations.update(...specs).then(
        (response) => {
          this.$delete(this.pendingApiCalls, `destination-${destination.id}`);

          if (!response) return;

          const index = this.menu.destinations.findIndex(
            (el) => el.id === destination.id,
          );

          if (index > -1) {
            this.$set(
              this.menu.destinations[index],
              "enabled",
              response.data.data.enabled,
            );

            this.$set(
              this.menu.destinations[index],
              "promoted",
              response.data.data.promoted ? 1 : 0,
            );

            this.$set(
              this.menu.destinations[index],
              "is_public",
              response.data.data.is_public,
            );
          }

          this.$store.dispatch("addNotification", {
            message: this.$t("app_page.destination_updated", {
              name: destination.name,
            }),
          });
        },
        () => {
          this.pendingApiCalls = this.pendingApiCalls.filter(
            (el) => el !== `destination-${destination.id}`,
          );
        },
      );
    },
    transformDestinationOrdersForSave(destinations) {
      if (!destinations) return;

      let reorderedDestinations = {};

      for (const [i, destination] of destinations.entries()) {
        reorderedDestinations[destination.id] = i;
      }

      return reorderedDestinations;
    },
    async getAppMenu() {
      try {
        const params = [
          this.groupId,
          this.groupMenuId,
          {
            with_disabled: 1,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupMenus.get(...params);

        this.menu = response.data.data;

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }
    },
    reorderGroupMenuDestinations() {
      if (!this.groupId || !this.menu.id) {
        return;
      }

      const newOrder = {
        order: this.transformDestinationOrdersForSave(this.menu.destinations),
      };

      const params = [this.groupId, this.menu.id, newOrder];

      this.$api.groupMenuDestinations.reorder(...params).then((response) => {
        if (response && response.status === 200) {
          this.$store.dispatch("addNotification", {
            message: this.$t("app_page.order_updated"),
          });
        }
      });
    },
    async deleteGroupMenuDestination(id) {
      try {
        if (!id) return;

        const confirmed = await this.$refs.confirmationDialog.open(
          this.$t("app_page.delete_warning"),
          this.$t("common.no"),
          this.$t("common.yes"),
        );

        if (!confirmed) return;

        const params = [this.groupId, this.menu.id, id];

        this.$set(this.pendingApiCalls, `destination-${id}`, true);

        const response = await this.$api.groupMenuDestinations.delete(
          ...params,
        );

        if (response && response.status === 200) {
          this.$store.dispatch("addNotification", {
            message: this.$t("app_page.delete_success"),
          });

          const index = this.menu.destinations.findIndex(
            (destination) => destination.id === id,
          );

          if (index !== -1) this.$delete(this.menu.destinations, index);
        }
      } catch (error) {
        if (error) {
          this.$delete(this.pendingApiCalls, `destination-${id}`);
          this.errorMessageShow(error);
          return;
        }
      }
    },
    editDestination(destination) {
      if (destination.component !== "ArticlePage") return;

      const params = JSON.parse(destination.params);

      this.$router.push({
        name: "app_page_wizard_edit",
        params: { menu_id: this.menu.id, article_id: params.article_id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.vue-draggable {
  .draggable-handle {
    cursor: grabbing;
  }
}
</style>
